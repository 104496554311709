import { API_BASE_URL } from "../../utils/urls";
import { FETCH_DEVICES_START, FETCH_DEVICES_FAILED, FETCH_DEVICES_SUCCESS, FETCH_DEVICE_TYPE } from "../actionTypes/device";
import http from "../../utils/http-service";
import {decrypt} from "../../utils/encryption";
import {logout} from "./auth";

export const getDevices = () => async(dispatch) => {
  dispatch({type: FETCH_DEVICES_START})
  try {
    const res = await http.get(`${API_BASE_URL}/device/e_getAll`);
    let devices=res.data.data
    devices=decrypt(devices);
    devices=JSON.parse(devices);

     let Bp=false;
     let Bg=false;
     let Temp=false;
     let Weight=false;
     let PO=false;



    //(devices.devices);
    for (let index = 0; index < devices.devices.length; index++) {

      if(devices.devices[index].type===1){
        Bg=true
      }
      if(devices.devices[index].type===2){
        Bp=true
      }
      if(devices.devices[index].type===3){
        Temp=true
      }
      if(devices.devices[index].type===4){
        PO=true
      }
      if(devices.devices[index].type===5){
        Weight=true
      }
      if(devices.devices[index].type===7){
        Bg=true
      }
      if(devices.devices[index].type===14 ||
         devices.devices[index].type===15 ||
         devices.devices[index].type===16 ||
         devices.devices[index].type===17 ||
         devices.devices[index].type===18 ||
         devices.devices[index].type===19 ||
         devices.devices[index].type===20 ||
       devices.devices[index].type===21||
       devices.devices[index].type===28){
        Bg=true
      }
    }
    // devices.devices.map((i)=>{
    // })
    let dev={devices,Bp,Bg,Temp,Weight,PO}

    dispatch({type: FETCH_DEVICES_SUCCESS, payload: dev});

    const data = devices;

    let device = data.devices.filter((device)=> device.type === 1 ||device.type === 7 || device.type === 14 || device.type === 15 ||device.type === 17 || device.type === 16 || device.type === 18 ||device.type === 19 || device.type === 20 || device.type === 21 || device.type === 28 )

    dispatch({type: FETCH_DEVICE_TYPE, payload: device[0]})
  }  catch (error) {
      if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

      }
      dispatch({type: FETCH_DEVICES_FAILED})

  }
}
